import React from 'react';
import PropTypes from 'prop-types';
import Immutable              from 'immutable';

export default class Phrase extends React.Component {
    static propTypes = {
        site: PropTypes.any.isRequired
    };

    render() {
        const { site } = this.props;
        return (
            <section className="container phrase">
                <h2 className="h1 phrase--title">{site.get('tagline')}</h2>
            </section>
        );
    }
}
