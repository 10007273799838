import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as PageActions       from 'actions/SiteActions';
import { connect }            from 'react-redux';
import Immutable              from 'immutable';

import PageContent            from 'components/page/PageContent';


@connect(state => ({page: state.page}))

export default class Page extends React.Component {
    static propTypes = {
        page:               PropTypes.any.isRequired,
        dispatch:           PropTypes.func.isRequired
    };

    static needs = [
        //PageActions.getPartition
    ];

    render() {
        const { page, dispatch } = this.props;
        return (<div><PageContent page={page} {...bindActionCreators(PageActions, dispatch)}  /></div>);
    }
}
