import request from 'axios';

process.env.API = process.env.API == undefined ? window.__HOSTNAME__ : process.env.API;
const API_URL = `${process.env.API}/front/v1`;

export function getYmJsEventCode() {
    process.env.hostname = process.env.hostname == undefined ? window.__HOSTNAME__ : process.env.hostname;
    return {
        type:    'GET_YM_JS_CODE',
        promise: request.get(`${API_URL}/reception/${process.env.hostname}/getYmJsEventCode/`)
    }
}

export function getStaff() {
    process.env.hostname = process.env.hostname == undefined ? window.__HOSTNAME__ : process.env.hostname;
    return {
        type:    'GET_STAFF_R',
        promise: request.get(`${API_URL}/reception/${process.env.hostname}/getStaff/`)
    }
}

export function getPossibleDays( data ) {
    process.env.hostname = process.env.hostname == undefined ? window.__HOSTNAME__ : process.env.hostname;
    return {
        type:    'GET_POSSIBLE_DAYS',
        promise: request.get(`${API_URL}/reception/${process.env.hostname}/getPossibleDays/?causeid=${data.causeID}&doctorid=${data.doctorID}`)
    }
}

export function getOneDay( data ) {
    process.env.hostname = process.env.hostname == undefined ? window.__HOSTNAME__ : process.env.hostname;
    return {
        type:    'GET_DAY',
        promise: request.get(`${API_URL}/reception/${process.env.hostname}/getDay/?causeid=${data.causeID}&doctorid=${data.doctorID}&year=${data.year}&month=${data.month}&week_num=${data.week}&day=${data.day}`)
    }
}

export function save( data ) {
    process.env.hostname = process.env.hostname == undefined ? window.__HOSTNAME__ : process.env.hostname;
    return {
        type:    'GET_SAVE',
        promise: request.get(`${API_URL}/reception/${process.env.hostname}/save/?${data}`)
    }
}

export function clear() {
    return {
        type: 'CLEAR_SAVE'
    }
}