import React                    from 'react';
import {
    Router,
    Route,
    Link,
    hashHistory }            from 'react-router';

import App                      from 'components/index';
import Main                     from 'components/main/Main';
import Page                     from 'components/page/Page';
import LegalMention             from 'components/legalMention/LegalMention';
import OfferOne                 from 'components/slider/OfferOne';
import NewsOne                  from 'components/news/NewsOne';
import Reviews                  from 'components/speak/Reviews';
import AddReviews               from 'components/speak/AddReviews';
import Reception                from 'components/reception/Reception';
import Staff                    from 'components/about/Staff';
import Error404                 from 'components/error/Error404';


export default (
    <Router history={hashHistory}>
        <Route name="app" path="/" component={App} type='app'>
            <Route path='/offers/:alias/' component={OfferOne} type='offer'/>
            <Route path='/news/:alias/' component={NewsOne} type='news'/>
            <Route path='/legal-mention/' component={LegalMention} type='LegalMention'/>
            <Route path='/reviews/' component={Reviews} type='reviews'/>
            <Route path='/reviews/add/' component={AddReviews} type='addreviews'/>
            <Route path='/reception/' component={Reception} type='reception'/>
            <Route path='/staff/' component={Staff} type='staff'/>
        </Route>
    </Router>
);