import React from 'react';
import PropTypes from 'prop-types';
import Immutable              from 'immutable';

export default class Error404 extends React.Component {
    static propTypes = {
       // site: PropTypes.any.isRequired
    };

    render() {
        const { } = this.props;
        return (
            <section className="contact">
                <h1>Ошибка 404</h1>
            </section>
        );
    }
}
