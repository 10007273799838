import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Immutable              from 'immutable';

export default class Price extends React.Component {
    static propTypes = {
        site:   PropTypes.any.isRequired,
        price:  PropTypes.any.isRequired
    };

    render() {
        const { price, site, dispatch } = this.props;
        let links = JSON.parse( site.get("links") && site.get("links") != '' ? site.get("links") : "{}");

        if(Immutable.List.isList(price) != false) {
            return (
                <section className="container price">
                    <div className="price__text">{site.get('about_promo')}</div>

                    {links.price &&

                        <div className="price__link">
                            <div dangerouslySetInnerHTML={{__html: '<svg class="price__link--svg" version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="36.3px" height="40px" viewBox="0 0 36.3 40" xml:space="preserve"><g> <path fill="#2196F3" d="M29.4,0H4.1v5.5H0V20h4.1v20h32.2V6.5L29.4,0z M21.7,12.1v1.2h-3v3h-1.4V9.3h4.8v1.2h-3.4v1.7H21.7zM16.2,12.9c0,0.6-0.1,1.1-0.2,1.5c-0.2,0.5-0.4,0.9-0.8,1.2c-0.2,0.2-0.6,0.4-1,0.6c-0.3,0.1-0.7,0.2-1.3,0.2h-2.7V9.3h2.6c0.6,0,1,0,1.3,0.1c0.4,0.1,0.8,0.3,1.1,0.6c0.3,0.3,0.5,0.7,0.7,1.1C16.1,11.6,16.2,12.2,16.2,12.9L16.2,12.9z M5.1,13.7v2.7H3.7V9.3h2.3c0.9,0,1.4,0,1.7,0.1c0.4,0.1,0.8,0.3,1,0.7c0.3,0.4,0.4,0.8,0.4,1.4c0,0.4-0.1,0.8-0.2,1.1c-0.2,0.3-0.4,0.5-0.6,0.7c-0.2,0.2-0.5,0.3-0.7,0.3c-0.3,0.1-0.8,0.1-1.5,0.1H5.1z M34,37.7H6.4V20h19.6V5.5H6.4V2.3h22C29,2.9,33.4,6.9,34,7.5C34,8.4,34,35.5,34,37.7L34,37.7z M34,37.7"/> <path fill="#2196F3" d="M14.1,10.9c-0.2-0.2-0.4-0.3-0.7-0.3c-0.2-0.1-0.6-0.1-1.2-0.1h-0.6v4.7h1.1c0.4,0,0.7,0,0.9-0.1 c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.3-0.4,0.4-0.7c0.1-0.3,0.1-0.7,0.1-1.3c0-0.5,0-1-0.1-1.3C14.4,11.3,14.3,11,14.1,10.9 L14.1,10.9z M14.1,10.9"/> <path fill="#2196F3" d="M7.4,10.8c-0.1-0.2-0.3-0.3-0.6-0.3c-0.2,0-0.5,0-1,0H5.1v2h0.8c0.6,0,0.9,0,1.1-0.1 c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.2,0.2-0.3,0.2-0.6C7.6,11.2,7.5,11,7.4,10.8L7.4,10.8z M7.4,10.8"/> </g> </svg>'}} />

                            <a target="_blank" className="price__link-item" href={links.price}>Посмотреть прайс</a>
                        </div>
                    }

                    <div className="price__block">
                        <div className="price__wrap">
                            <a href="javascript:void(0);" className="price__wrap-item price__wrap-item--big" dangerouslySetInnerHTML={{__html: price.size>0 ? `<p class="price__wrap-item--title">${price.get(0).get('name')}</p><p class="price__wrap-item--cost">${price.get(0).get('cost')}</p>` : '' }}></a>
                            <div className="price__wrap">
                                <a href="javascript:void(0);" className="price__wrap-item price__wrap-item--small" dangerouslySetInnerHTML={{__html: price.size>1 ? `<p class="price__wrap-item--title">${price.get(1).get('name')}</p><p class="price__wrap-item--cost">${price.get(1).get('cost')}</p>` : '' }}></a>
                                <a href="javascript:void(0);" className="price__wrap-item price__wrap-item--small" dangerouslySetInnerHTML={{__html: price.size>2 ? `<p class="price__wrap-item--title">${price.get(2).get('name')}</p><p class="price__wrap-item--cost">${price.get(2).get('cost')}</p>` : '' }}></a>
                            </div>
                        </div>
                        <div className="price__wrap">
                            <a href="javascript:void(0);" className="price__wrap-item price__wrap-item--small" dangerouslySetInnerHTML={{__html: price.size>3 ? `<p class="price__wrap-item--title">${price.get(3).get('name')}</p><p class="price__wrap-item--cost">${price.get(3).get('cost')}</p>` : '' }}></a>
                            <a href="javascript:void(0);" className="price__wrap-item price__wrap-item--long" dangerouslySetInnerHTML={{__html: price.size>4 ? `<p class="price__wrap-item--title">${price.get(4).get('name')}</p><p class="price__wrap-item--cost">${price.get(4).get('cost')}</p>` : '' }}></a>
                        </div>
                    </div>
                </section>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}
