import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators }   from 'redux';
import { connect }              from 'react-redux';
import Immutable                from 'immutable';
import { Link }                 from 'react-router';


@connect(state => ({ news: state.news, staff: state.staff }))

export default class About extends React.Component {
    static contextTypes = {
      router: PropTypes.object.isRequired
    };
    static propTypes = {
        site:       PropTypes.any.isRequired,
        dispatch:   PropTypes.func.isRequired
    };

    state = {
      fullScreen: false
    };

    handleClick = (event) => {
        console.log('event', event);
        //event.preventDefault();
        //this.context.router.pushState(null, event.target.pathname);
    };

    toggleFullScreen = () => {
      this.setState({
          fullScreen: !this.state.fullScreen
      });
    };

    renderPreview = site => {
        let  result = undefined;
        if(this.state.fullScreen){
            result = (
                <div className="modal__wrap" id="Preview" style={{display: 'block'}}>
                    <div className="modal modal--open">
                        <button type="button" className="modal__close" onClick={this.toggleFullScreen}>
                            <span>×</span>
                        </button>
                        <div>
                            <img className="about__text-license-photo about__text-license-photo--fullscreen"
                                    src={'/userfiles/news/full/'+site.get('preview')}
                                    onClick={this.toggleFullScreen}
                                    alt=""
                                    title="Превью" />
                        </div>
                    </div>
                </div>
            )
        }else{
            result = (<img className="about__text-license-photo"
                          src={'/userfiles/news/big/'+site.get('preview')}
                          onClick={this.toggleFullScreen}
                          alt=""
                          title="Превью" />)
        }
        return result;
    };

    render() {
        const { site, staff, news } = this.props;

        return (
            <section className="container about">
                <h2 className="h2 about--title">О компании</h2>
                <div className="about__text">
                    {site.get('about_company')}
                    <div className="about__text-list">
                        <div className="about__text-license">
                            {site.get('preview') ?
                                this.renderPreview(site)
                                :
                                <span/>
                            }
                            {site.get('about_images') ?
                                <span className="about__text-license-text">{site.get('about_images')}</span>
                                :
                                <span/>
                            }
                        </div>
                    </div>
                </div>
                <div className="about__doctor">
                    {site.get('about_staff')}
                    {Immutable.List.isList(staff)!=false ?
                        <div className="about__doctor-one">
                            <div className="about__doctor-one-link"><Link to={`/staff/`}>Показать всех сотрудников</Link></div>
                            <div className="about__doctor-one-wrap">
                                <img width="151" height="151" src={staff.get(0).get('images').get('thumb')} alt={staff.get(0).get('name')} title={staff.get(0).get('name')} />
                                <p className="about__doctor-one-post">{staff.get(0).get('name')}, {staff.get(0).get('whois')}</p>
                            </div>
                            <p className="about__doctor-one-quote">{staff.get(0).get('short')}</p>
                        </div>
                        :
                        ''
                    }

                </div>
                <div className="clearfix"></div>
                {Immutable.List.isList(news)!=false ?
                    <div className="about__news">
                        <div className="about__news-item">
                            <span className="about__news-item-date">{news.get(-1).get('dates').get('format')}</span>
                            <Link className="about__news-item-title" to={`/news/${news.get(-1).get('alias')}/`} >{news.get(-1).get('content').get('title')}</Link>
                        </div>
                        <Link className="about__news-all" to={`/news/${news.get(-1).get('alias')}/`}>Посмотреть все новости</Link>
                        <div className="clearfix"></div>
                    </div>
                    :
                    ''
                }
            </section>
        );

    }
}
