import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators }  from 'redux';
import Immutable               from 'immutable';
import { connect }             from 'react-redux';

import * as SiteActions       from 'actions/SiteActions';
import * as OffersActions     from 'actions/OffersActions';
import * as PriceActions      from 'actions/PriceActions';
import * as NewsActions       from 'actions/NewsActions';
import * as ReviewsActions    from 'actions/ReviewsActions';
import * as StaffActions      from 'actions/StaffActions';
import * as ShareAction       from 'actions/ShareAction';

import Header            from 'components/header/Header';
import Phrase            from 'components/phrase/Phrase';
import Slider            from 'components/slider/Slider';
import Price             from 'components/price/Price';
import About             from 'components/about/About';
import Speak             from 'components/speak/Speak';
import Contact           from 'components/contact/Contact';
import Footer            from 'components/footer/Footer';
import Eye               from 'components/eyePanel/Eye';

@connect(state => ({
    //meta:   state.meta,
    staff:   state.staff,
    news:    state.news,
    site:    state.site,
    reviews: state.reviews,
    price:   state.price,
    offers:  state.offers,
    eye:     state.eye
}))

export default class MainView extends React.Component {

    static propTypes = {
        children: PropTypes.object,
        dispatch: PropTypes.func.isRequired
    };


    static needs = [
        StaffActions.getStaff,
        SiteActions.getSiteinfo,
        ShareAction.getSiteShare,
        PriceActions.getPrices,
        NewsActions.getNews,
        ReviewsActions.getReviews,
        OffersActions.getOffers
    ];

    render() {
        const { site, staff, news, price, offers, reviews, dispatch, params } = this.props;

        return (
            <div className={this.props.eye.active ? this.props.eye.className : ''}>
                <Eye dispatch={dispatch}/>
                <Header params={params} site={site} {...bindActionCreators(SiteActions, dispatch)} />

                <Phrase site={site} {...bindActionCreators(SiteActions, dispatch)} />

                <Slider offers={offers} {...bindActionCreators(OffersActions, dispatch)} />

                <Price price={price} site={site} {...bindActionCreators(SiteActions, dispatch)} />

                <About site={site} staff={staff} news={news} {...bindActionCreators(SiteActions, dispatch)} />

                <Speak site={site} reviews={reviews} {...bindActionCreators(ReviewsActions, dispatch)}  />

                <Contact params={params} site={site} {...bindActionCreators(SiteActions, dispatch)} />

                <section>{this.props.children}</section>

                <Footer params={params} site={site} {...bindActionCreators(SiteActions, dispatch)} />
            </div>
        );
    }
}
