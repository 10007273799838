import React from 'react';
import PropTypes from 'prop-types';
import {get} from '../../lib/cookie';
import {search} from '../../lib/searchParamInStr';

export default class PhoneList extends React.Component {

    static propTypes = {
        params: PropTypes.object.isRequired
    };

    state = {
      show: false
    };

    toggleShow = () => {
      this.setState({
          show: !this.state.show
      });
    };

    render() {
        let {phones, params} = this.props,
            phoneList = [],
            defaultList = [],
            result = undefined;
        phones = JSON.parse( phones && phones!== '' ?  phones : '{}');

        // Берём куку (если она есть ещё)
        let param = params ? get('airstom_calltrack_info', params) : {};

        const referer = param.referer ? param.referer : 'default',
            uri = param.uri ? param.uri : 'default';
        /**
         * Раскоментировать для отладки
         */
        // console.log('param.referer', param.referer, referer);
        // console.log('param.uri', param.uri, uri);
        // console.log('param', param);

        for(let key in phones){
            let phoneType = phones[key].type,
              phoneName = phones[key].name,
              phoneReferer = phones[key].referer;

            // Проверим есть ли GET параметры
            if (uri.search(/\?/gm) >= 0 || referer.search(phoneReferer) >= 0) {
                /**
                 * Раскоментировать для отладки
                 */
                // console.log('====== For ======');
                // console.log('search(' + uri + ', ' + phoneType + ')', search(uri, phoneType), 'phoneType !== ""', phoneType !== "", 'phoneType !== "default"', phoneType !== "default");
                // console.log('total: ', )
                // console.log("type:", search(uri, phoneType) && phoneType !== "" && phoneType !== "default", ', phoneType: ' + phoneType, ', uri: ' + uri);
                // console.log('referer:', referer.search(phoneReferer) >= 0 && phoneReferer !== '' && phoneReferer !== 'default', 'phoneReferer: ' + phoneReferer, 'referer: ' + referer);
                // console.log("phones[key]: ", phones[key]);
                // console.log('====== End ======');
                // console.log(' ');
                if (
                  ( search(uri, phoneType) && phoneType !== '' && phoneType !== 'default' ) ||
                  ( referer.search(phoneReferer) >= 0 && phoneReferer !== '' && phoneReferer !== 'default' )
                ) {
                    phoneList.push(
                      <li key={key} className="phone-list__it">
                          <a href={'tel:' + phoneName}>{phoneName}</a>
                      </li>
                    );
                    break;
                }

            }

            if ('default' === phoneType && 'default' === phoneReferer) {
              defaultList.push(
                  <li key={key} className="phone-list__it">
                      <a href={'tel:' + phoneName}>{phoneName}</a>
                  </li>
                );
            }
        }

        if ( !!phoneList.length ) {
          result = phoneList.length > 2 ? (
            <ul key={0}>
              {phoneList[0]}
              {phoneList[1]}
              <a className="phone-list__toggle-link" onClick={this.toggleShow}>Ещё</a>
              <ul key={0} className="dropdown-menu" style={this.state.show ? {display:'block'} : {display:'none'}}>
                {phoneList.map(function(it, i){
                  if(i>1){
                    return it;
                  }
                })}
              </ul>
            </ul>
          ) : phoneList;
        } else {
          result = defaultList;
        }


        return (
            <div className="phone-list">
                {result}
            </div>
        );
    }
}
