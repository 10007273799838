import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators }   from 'redux';
import Immutable                from 'immutable';
import { Link }                 from 'react-router';

export default class Slider extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired
    };
    static propTypes = {
        offers: PropTypes.any.isRequired
    };

    handleClick = (event) => {
        event.preventDefault();
        console.log('1', 100);
        //this.context.router.pushState(null, event.target.pathname);
    };

    render() {
        const { offers, dispatch } = this.props;
        let obj = this;

        if(Immutable.List.isList(offers) != false) {
            return (
                <section className="container slider">
                    <div className="fotorama"
                         data-fit="cover"
                         data-width="100%"
                         data-height="380">
                        {offers.map(function(o, index){
                            //let link = <Link className="slider__caption--btn" to="/asad/">100500</Link>;
                            return (
                                <img key={index} src={o.get('images').get('big')}
                                     data-caption={`<div id="slider__caption-${index}" class="slider__caption">${o.get('name')}<br /><a class="slider__caption--btn" href="${'/offers/'+o.get('link')+'/'}" onclick="handleClick()">Подробнее</a></div>`} />
                            )
                        })}
                    </div>
                </section>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}
