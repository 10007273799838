import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import * as SiteActions       from 'actions/SiteActions';
import { Link }               from 'react-router';
import Immutable              from 'immutable';

@connect(state => ({ site: state.site }))

export default class LegalMention extends React.Component {

  static contextTypes = {
    router: PropTypes.object.isRequired
  };

  static propTypes = {
    site: PropTypes.any.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  componentWillReceiveProps = (nextProps) => {
    Modal('#modalReview');
  };

  close = (selector) => {
    this.context.router.push({query: {}, state: {}, pathname:'/'});
    ModalClose(selector);
  };

  static needs = [
    SiteActions.getSiteinfo
  ];

  render() {
    const { site } = this.props;
    let legalMention  = JSON.parse(site.get('legal_mention') && site.get('legal_mention') !== '' ? site.get('legal_mention') : '{}'),
        links         = !!legalMention.links ? legalMention.links : {},
        linkTitles    = !!legalMention.linkTitles ? legalMention.linkTitles : {},
        file          = JSON.parse(site.get('file') && site.get('file') !== '' ? site.get('file') : '{}'),
        divStyle      = { display: 'block' };

    return (
      <div className="modal__wrap" style={divStyle}>
        <div id="modalReview" className="modal modal--open">
          <button type="button" className="modal__close" onClick={this.close.bind(this, '#modalReview')}><span>&times;</span></button>

          <p className="modal__title">{ !!legalMention.title ? legalMention.title : 'Правовая информация' }</p>

          <div className="modal__form"
               dangerouslySetInnerHTML={{
                 __html: legalMention.content
               }}></div>

          {
            links.price &&
            <div>
              <br/>
              <a target="_blank" href={links.price}>
                { !!linkTitles.price ? linkTitles.price : 'Прайс лист' }
              </a>
              <br/>
            </div>
          }
          {
            links.regulations &&
            <div>
              <br/>
              <a target="_blank" href={links.regulations}>
                { !!linkTitles.regulations ? linkTitles.regulations : 'Правила оказания платных услуг' }
              </a>
              <br/>
            </div>
          }
          {
            links.agreement &&
            <div>
              <br/>
              <a target="_blank" href={links.agreement}>
                { !!linkTitles.agreement ? linkTitles.agreement : 'Пользовательское соглашение' }
              </a>
              <br/>
            </div>
          }
          {
            links.power &&
            <div>
              <br/>
              <a target="_blank" href={links.power}>
                { !!linkTitles.power ? linkTitles.power : 'Адреса и телефоны органов исполнительной власти' }
              </a>
              <br/>
            </div>
          }
          {
            links.evidence &&
            <div>
              <br/>
              <a target="_blank" href={links.evidence}>
                { !!linkTitles.evidence ? linkTitles.evidence : 'Свидетельство ЕГРЮЛ' }
              </a>
              <br/>
            </div>
          }
          {
            links.workerData &&
            <div>
              <br/>
              <a target="_blank" href={links.workerData}>
                { !!linkTitles.workerData ? linkTitles.workerData : 'Сведения о работниках' }
              </a>
              <br/>
            </div>
          }

          {file.label &&
          <div>
            <br/>
            <a
               target="_blank"
               title={file.link}
               href={'/license.'+file.filename.split('.')[1]}
            >
              {file.link}
            </a>
          </div>
          }

        </div>
      </div>
    );
  }
}
