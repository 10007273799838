import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { Link }               from 'react-router';
import Immutable              from 'immutable';


@connect(state => ({ reviews: state.reviews }))

export default class Reviews extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired
    };
    static propTypes = {
        reviews:  PropTypes.any.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    componentWillReceiveProps = (nextProps) => {
        Modal('#modalReview');
    };

    close = (selector) => {
        this.context.router.push({query: {}, state: {}, pathname:'/'});
        ModalClose(selector);
    };


    render() {
        const { reviews, dispatch } = this.props;
        let divStyle = { display: 'block' };

        return (
            <div className="modal__wrap" style={divStyle}>
                <div id="modalReview" className="modal modal--open">
                    <button type="button" className="modal__close" onClick={this.close.bind(this, '#modalReview')}><span>&times;</span></button>
                    <p className="modal__title">Отзывы</p>
                    <div className="modal__form">
                        <div className="review">
                            {reviews.map(function (item, index) {
                                return(
                                    <div key={index} className="review__item">
                                        <div className="review__item-author">{item.get('name')}</div>
                                        <div className="review__item-text">{item.get('text')}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="modal__footer">
                        <Link to="/reception/" className="btn btn--center btn--long">Записаться на приём</Link>
                    </div>
                </div>
            </div>
        );
    }
}
