const defaultState = {
    active: false
};

export default function EyeReducer(state = defaultState, action) {

    switch (action.type) {
        case 'GET_EYE':
            return action.data;
        case 'SET_EYE':
            return action.data;
        default:
            return state;
    }
}

