import React from 'react';
import PropTypes from 'prop-types';
import PhoneList              from '../other/PhoneList';
import {Link} from 'react-router';

export default class Footer extends React.Component {
    static propTypes = {
        site:   PropTypes.any.isRequired,
        params: PropTypes.object.isRequired
    };

    static needs = [
        //PageActions.getPartition
    ];

    render() {
        const { site } = this.props;
        let legalMention  = JSON.parse(site.get('legal_mention') && site.get('legal_mention') !== '' ? site.get('legal_mention') : '{}'),
            links         = legalMention.links,
            file          = JSON.parse(site.get('file') && site.get('file') !== '' ? site.get('file') : '{}');

        return (
            <footer className="footer">
                <div className="container">
                    <div className="footer__left">
                        <div className="footer__address">
                            {site.get('address')}
                            <PhoneList params={this.props.params} phones={site.get('phone')}/>
                        </div>
                        <div className="footer__social">
                            <div className="footer__social-item footer__social--twitter"/>
                            <div className="footer__social-item footer__social--vk"/>
                            <div className="footer__social-item footer__social--ok"/>
                            <div className="footer__social-item footer__social--fb"/>
                        </div>

                        {file.label &&
                            <div className="phone-list__it">
                                <a className="footer__link"
                                   target="_blank"
                                   title={file.link}
                                   href={'/license.'+file.filename.split('.')[1]}
                                >
                                    {file.link}
                                </a>
                            </div>
                        }
                        <div className="footer__oferta">Имеются противопоказания проконсультируйтесь с врачом, Цены на сайте не являются публичной офертой. Стоимость лечения уточняется врачом перед началом лечения.</div>
                    </div>
                    <div className="footer__right">
                        <span>© Сделано на «</span><a href="http://airstom.ru">Аирстом</a><span> – управление мед. клиникой»</span>
                        {
                            !!links.agreement ||
                            !!links.evidence ||
                            !!links.power ||
                            !!links.price ||
                            !!links.regulations ||
                            !!links.workerData ||
                            !!legalMention.title ||
                            !!legalMention.content ||
                            !!file.label
                              ? <Link className="footer__link" to="/legal-mention/">Правовая информация</Link>
                              : ``
                        }
                    </div>
                    <div className="clearfix"/>
                </div>
            </footer>
        );
    }
}
