import Immutable from 'immutable';

const defaultState = new Immutable.List();

export default function receptionPossibleDaysReducer (state = defaultState, action) {
  switch (action.type) {
    case 'GET_POSSIBLE_DAYS':
      if (!action.res.data) {
        console.log('state', state);
        return state;
      } else {
        let data = new Immutable.fromJS(action.res.data).toJSON();
        data.possibleDays = data.possibleDays.map(value => new Date(value));

        console.log('state data', data);
        return data;
      }
    default:
      return state;
  }
}