const defaultState = false; //по умолчанию выключено

export default function EyeReducer(state = defaultState, action) {

    switch (action.type) {
        case 'SET_VOICE':
            return action.data;
        default:
            return state;
    }
}

