import findGetParameter from "./findGetParameter";

/**
 * @param uri       uri в котором нужно искать параметр
 * @param param     искомый параметр
 * @returns {boolean}
 */
export const search = (uri, param) => {
    const data = param.split("=");
    return findGetParameter(uri, data);
};