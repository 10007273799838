import React from 'react';
import PropTypes from 'prop-types';
import Immutable     from 'immutable';




export default class PageContent extends React.Component {
    static propTypes = {
        page:           PropTypes.instanceOf(Immutable.Map).isRequired,
        getPartition:   PropTypes.func.isRequired
    };

    render() {
        const { page, format } = this.props;
        if(Immutable.List.isList(page) != false) {
            return (
                <div>{page.get('content').get('meta_title')}</div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}
