import request from 'axios';

process.env.API = process.env.API == undefined ? window.__API_URL__ : process.env.API;
const API_URL = `${process.env.API}/front/v1/prices`;

export function getPrices() {
    return {
        type: 'GET_PRICES',
        promise: request.get(`${API_URL}/${process.env.hostname}/get/`)
    }
}
