import React                from 'react';
import * as voiceAction     from 'actions/VoiceAction'; // подключаем управление голосом
import {connect}            from 'react-redux';
@connect(state => ({
    voice: state.voice,
    eye: state.eye
}))


export default class Voice extends React.Component {

    state = {
        play: false,
        id: Math.round(Math.random() * (100 - 1) + 1)
    };

    speak = (text) => {
        this.props.dispatch(voiceAction.playVoice(text));
    };



    sound = () => {
        this.setState({
            play: !this.state.play
        });
        if(typeof(window) !== 'undefined'){
            if(this.state.play === false){
                let worlds = this.props.text.replace(/(&nbsp;|&laquo;|&mdash;|\n|&raquo;|&ndash;)/g, '');
                this.speak({
                    text: worlds.replace(/(\. )/g, '.').split('.'),
                    volume: this.props.eye.volume,
                    id: this.state.id,
                    speech: this.props.voice.speech,
                    object: this
                });
            } else {
                this.props.dispatch(voiceAction.stopVoice({
                    text: false,
                    id: this.state.id,
                    speech: this.props.voice.speech
                }));
            }

        }
    };

    render(){
        let labelText = undefined,
            audio = undefined;

        if(this.state.play === true && this.props.voice.id === this.state.id){
            labelText = (
                <span>
                    <i className="fa fa-volume-up"/> Стоп
                </span>
            );
        }else{
            labelText = (
                <span>
                    <i className="fa fa-volume-up"/> Озвучить
                </span>
            );
        }


        return (
            <button className="btn-eye"
                    id={'voice:'+this.state.id}
                    onClick={this.sound}>
                {audio}
                {labelText}
            </button>
        );
    }
}