const findGetParameter = (search, parameter) => {
    let result = undefined,
        parameterName = parameter[0],
        parameterValue = parameter[1],
        tmp = [];

    search.replace("?", "&")
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            // проверим требуется ли соответствие значения параметра
            let checkValue = parameterValue === undefined ? true : tmp[1] === parameterValue;
            if (tmp[0] === parameterName && checkValue) result = true;
        });

    return result;
};

export default findGetParameter;