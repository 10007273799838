import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators }  from 'redux';
import Immutable               from 'immutable';
import { connect }             from 'react-redux';
import { Link }                 from 'react-router';

@connect(state => ({ offers: state.offers }))

export default class OfferOne extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired
    };
    static propTypes = {
        offers:   PropTypes.object,
        dispatch: PropTypes.func.isRequired
    };


    close = (selector) => {
        // this.context.router.push({query: {}, state: {}, pathname:'/'});
        // ModalClose(selector);
        location.href = '/';
    };


    render() {
        const { offers, params, dispatch } = this.props;
        let divStyle = { display: 'block' };

        if(params.alias === undefined){
            return <div />;
        }

        let one = {};
        offers.map(item=>{
            if( item.get('link') === params.alias){
                one = item;
            }
        });

        return (
            <div className="modal__wrap" style={divStyle}>
                <div id="modalOffer" className="modal modal--open">
                    <button type="button" className="modal__close" onClick={this.close.bind(this, '#modalOffer')}><span>&times;</span></button>
                    <p className="modal__title">Акция «{one.get('name')}»</p>
                    <div>
                        <img className="modal__form--image" src={one.get('images').get('big')} />
                        <div dangerouslySetInnerHTML={{__html: one.get('description')}}></div>
                        <br />
                    </div>
                    <div className="modal__footer">
                        <Link to="/reception/" className="btn btn--center btn--long">Записаться на приём</Link>
                    </div>
                </div>
            </div>
        );
    }
}
