import React, { Component }   from 'react';
import { bindActionCreators } from 'redux';
import {connect}                from 'react-redux';
import * as eyeAction           from 'actions/EyeActions'; // подключаем режим для слепых


@connect(state => ({
    eye: state.eye
}))
export default class Eye extends Component {

    state = {
        setting: false,
        defaultParam: {
            active: true,
            color: 'while',
            img: true,
            size: 1,
            letterSpacing: 10,
            lineHeight: 10,
            serif: false,
            volume: 0.5,
            className: "eye-active eye-size1 eye-while eye-img-true eye-letterSpacing10 eye-lineHeight10"
        }
    };

    setSetting = setting => {
        let newSetting = this.props.eye;
        for (let attr in setting){
            newSetting[attr] = setting[attr];
        }
        newSetting.className = `eye-active eye-size${newSetting.size} eye-${newSetting.color} eye-img-${newSetting.img} eye-serif-${newSetting.serif} eye-lineHeight-${newSetting.lineHeight} eye-letterSpacing-${newSetting.letterSpacing}`;
        this.props.dispatch(eyeAction.setParam(newSetting));
    };

    componentDidMount = () => {
        this.props.dispatch(eyeAction.getParam());
    };

    toggleSetting = () => {
      this.setState({
          setting: !this.state.setting
      });
    };

    render() {
        if(this.props.eye.active){
            return (
                <div className="eye-panel__container">
                    <div className="eye-panel--active">
                        <div className="eye-panel__row">
                            <div className="eye-panel__row-item">
                                <a href="/" className="btn-eye btn-eye--panel">
                                    <i className="fa fa-arrow-circle-left"/> На главную
                                </a>
                            </div>
                            <div className="eye-panel__row-item">
                                Шрифт
                                <div className="eye-panel__font">
                                    <button className={"btn-eye  eye-panel__font-1 " +(this.props.eye.size === 1 ? 'btn-eye__active' : '')}
                                            onClick={()=>this.setSetting({
                                                size: 1
                                            })}>
                                        A
                                    </button>
                                    <button className={"btn-eye eye-panel__font-2 " +(this.props.eye.size === 2 ? 'btn-eye__active' : '')}
                                            onClick={()=>this.setSetting({
                                                size: 2
                                            })}>
                                        A
                                    </button>
                                    <button className={"btn-eye eye-panel__font-3 " +(this.props.eye.size === 3 ? 'btn-eye__active' : '')}
                                            onClick={()=>this.setSetting({
                                                size: 3
                                            })}>
                                        A
                                    </button>
                                </div>
                            </div>
                            <div className="eye-panel__row-item">
                                Цвет
                                <div className="eye-panel__color">
                                    <button className={"btn-eye btn-eye--panel btn-eye--while " +(this.props.eye.color === 'while' ? 'btn-eye__active' : '')}
                                            onClick={()=>this.setSetting({
                                                color: 'while'
                                            })}>
                                        Я
                                    </button>
                                    <button className={"btn-eye btn-eye--panel btn-eye--black " +(this.props.eye.color === 'black' ? 'btn-eye__active' : '')}
                                            onClick={()=>this.setSetting({
                                                color: 'black'
                                            })}>
                                        Я
                                    </button>
                                    <button className={"btn-eye btn-eye--panel btn-eye--blue " +(this.props.eye.color === 'blue' ? 'btn-eye__active' : '')}
                                            onClick={()=>this.setSetting({
                                                color: 'blue'
                                            })}>
                                        Я
                                    </button>
                                    <button className={"btn-eye btn-eye--panel btn-eye--yellow " +(this.props.eye.color === 'yellow' ? 'btn-eye__active' : '')}
                                            onClick={()=>this.setSetting({
                                                color: 'yellow'
                                            })}>
                                        Я
                                    </button>
                                    <button className={"btn-eye btn-eye--panel btn-eye--green " +(this.props.eye.color === 'green' ? 'btn-eye__active' : '')}
                                            onClick={()=>this.setSetting({
                                                color: 'green'
                                            })}>
                                        Я
                                    </button>
                                </div>
                            </div>
                            <div className="eye-panel__row-item">
                                <button onClick={this.toggleSetting}
                                        className="btn-eye btn-eye--panel">
                                    <i className="fa fa-cog"/> Настройки
                                </button>
                                <button  className="btn-eye btn-eye--panel"
                                         style={{marginLeft: '10px'}}
                                         onClick={()=>{
                                            this.setSetting({
                                                active: false
                                            });
                                            this.setState({
                                                setting: false
                                            });
                                        }}>
                                    <i className="fa fa-eye-slash" style={{marginRight:0}}/>&nbsp;
                                </button>
                            </div>
                            {
                                this.state.setting ?
                                    <div className="eye-panel__setting">
                                        <table>

                                            <tr>
                                                <td>
                                                    <label>
                                                        Изображения
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className="eye-panel__row-item">
                                                        <div className="eye-panel__setting-btn">
                                                            <button className={"btn-eye btn-eye--panel " + (this.props.eye.img ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        img: true
                                                                    })}>
                                                                Включить
                                                            </button>
                                                            <button className={"btn-eye btn-eye--panel " + (!this.props.eye.img ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        img: false
                                                                    })}>
                                                                Выключить
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>
                                                        Межстрочный интервал
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className="eye-panel__row-item">
                                                        <div className="eye-panel__setting-btn">
                                                            <button className={"btn-eye btn-eye--panel " + (this.props.eye.lineHeight === 10 ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        lineHeight: 10
                                                                    })}>
                                                                Одинарный
                                                            </button>
                                                            <button className={"btn-eye btn-eye--panel " + (this.props.eye.lineHeight === 15 ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        lineHeight: 15
                                                                    })}>
                                                                Полуторный
                                                            </button>
                                                            <button className={"btn-eye btn-eye--panel " + (this.props.eye.lineHeight === 20 ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        lineHeight: 20
                                                                    })}>
                                                                Двойной
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>
                                                        Расстояние между буквами
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className="eye-panel__row-item">
                                                        <div className="eye-panel__setting-btn">
                                                            <button className={"btn-eye btn-eye--panel btn-eye--letter10 " + (this.props.eye.letterSpacing === 10 ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        letterSpacing: 10
                                                                    })}>
                                                                Стандартное
                                                            </button>
                                                            <button className={"btn-eye btn-eye--panel btn-eye--letter15 " + (this.props.eye.letterSpacing === 15 ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        letterSpacing: 15
                                                                    })}>
                                                                Среднее
                                                            </button>
                                                            <button className={"btn-eye btn-eye--panel btn-eye--letter20 " + (this.props.eye.letterSpacing === 20 ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        letterSpacing: 20
                                                                    })}>
                                                                Большое
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>
                                                        Гарнитура
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className="eye-panel__row-item">
                                                        <div className="eye-panel__setting-btn">
                                                            <button className={"btn-eye btn-eye--panel " + (this.props.eye.serif === false ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        serif: false
                                                                    })}>
                                                                Без засечек
                                                            </button>
                                                            <button className={"btn-eye btn-eye--panel btn-eye--serif " + (this.props.eye.serif === true ? 'btn-eye__active' : '')}
                                                                    onClick={()=>this.setSetting({
                                                                        serif: true
                                                                    })}>
                                                                <span style={{"font-family": "serif !important"}}>С засечками</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <label>
                                                        Звук
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className="eye-panel__row-item">
                                                        <div className="eye-panel__setting-btn">
                                                            <button className="btn-eye btn-eye--panel"
                                                                    onClick={()=>this.setSetting({
                                                                        volume: this.props.eye.volume - 0.1 > 0 ? this.props.eye.volume - 0.1 : 0
                                                                    })}>
                                                                <i className="fa fa-minus-circle"/> Тише
                                                            </button>
                                                            <button className="btn-eye btn-eye--panel"
                                                                    onClick={()=>this.setSetting({
                                                                        volume: 0.5
                                                                    })}>
                                                                Нормально
                                                            </button>
                                                            <button className="btn-eye btn-eye--panel"
                                                                    onClick={()=>this.setSetting({
                                                                        volume: this.props.eye.volume + 0.1 < 1 ? this.props.eye.volume + 0.1 : 1
                                                                    })}>
                                                                <i className="fa fa-plus-circle"/> Громче
                                                            </button>
                                                            <div>
                                                                Уровень громкости - {Math.round(this.props.eye.volume * 100)}%
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>

                                        <div className="eye-panel__setting-bottom">
                                            <div className="eye-panel__row-item">
                                                <button className="btn-eye btn-eye--panel"
                                                        onClick={()=>this.setSetting(this.state.defaultParam)}>
                                                    <i className="fa fa-refresh"/> Вернуть настройки по умолчанию
                                                </button>
                                                <button className="btn-eye btn-eye--panel"
                                                        onClick={
                                                            ()=>{
                                                                this.setSetting({
                                                                    active: false
                                                                });
                                                                this.setState({
                                                                   setting: false
                                                                });
                                                            }
                                                        }>
                                                    <i className="fa fa-eye-slash"/> Обычная версия сайта
                                                </button>
                                                <button className="btn-eye btn-eye--panel btn-eye--panel-right"
                                                        onClick={this.toggleSetting}>
                                                    <i className="fa fa-chevron-circle-up" aria-hidden="true"/> Скрыть настройки
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                :
                                undefined
                            }

                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className="eye-panel">
                    <div className="eye-panel--begin">
                        <button
                            title="Включить режим для слепых"
                            className="btn-eye--begin"
                            onClick={()=>{
                                this.setSetting(this.state.defaultParam);
                            }}>
                            <i className="fa fa-eye" />
                        </button>
                    </div>
                </div>
            );
        }


    }
}