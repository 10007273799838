import React from 'react';
import PropTypes from 'prop-types';
import Immutable              from 'immutable';
import { Link }               from 'react-router';
import PhoneList              from '../other/PhoneList';

export default class Header extends React.Component {
    static propTypes = {
        site: PropTypes.any.isRequired,
        params: PropTypes.object.isRequired,
    };

    render() {
        const { site } = this.props;

        return (
            <header className="container header">
                <h1 className="header__logo">{site.get('name')}</h1>
                <div className="header__block">
                    <div className="header__block-address">
                        <p>{site.get('address')}</p>
                        <PhoneList params={this.props.params} phones={site.get('phone')}/>
                    </div>
                    <div className="header__block-phone">
                        <div className="header__block-schedule" dangerouslySetInnerHTML={{__html: site.get('time_work')}}/>
                    </div>
                </div>
                <div className="header__btn">
                    <Link className="btn header__btn--header" to="/reception/">Записаться на приём</Link>
                </div>
            </header>
        );
    }
}
