import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators }   from 'redux';
import Immutable                from 'immutable';
import { connect }              from 'react-redux';
import { Link }                 from 'react-router';

@connect(state => ({ news: state.news }))

export default class NewsOne extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired
    };
    static propTypes = {
        news:     PropTypes.object,
        dispatch: PropTypes.func.isRequired
    };

    close = (selector) => {
        this.context.router.push({query: {}, state: {}, pathname:'/'});
        ModalClose(selector);
    };

    componentWillReceiveProps = (nextProps) => {
        Modal('#modalNews');
    };

    render() {
        const { news, params } = this.props;
        let divStyle = { display: 'block' };

        return (
            <div className="modal__wrap" style={divStyle}>
                <div id="modalNews" className="modal modal--open">
                    <button type="button" className="modal__close" onClick={this.close.bind(this, '#modalNews')}><span>&times;</span></button>
                    <p className="modal__title">Новости</p>
                    <div className="modal__form">
                        <div className="news">
                            {news.map(function(i, index){
                                return(
                                    <div index={index} className={ params.alias==i.get('alias') ? 'news__item news__item--open' : 'news__item'}>
                                        <div className="news__item-date">{i.get('dates').get('format')}</div>
                                        <Link to={`/news/${i.get('alias')}/`} className="news__item-text">{i.get('content').get('title')}</Link>
                                        <div className="news__item-open" dangerouslySetInnerHTML={{__html: i.get('content').get('text')}}></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="modal__footer"></div>
                </div>
            </div>
        );
    }
}
