import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as ReceptionAction from 'actions/ReceptionAction';
import {connect} from 'react-redux';
//DatePicker settings start
import DatePicker from 'react-datepicker';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru)
setDefaultLocale('ru')
//DatePicker settings end
import Select from 'react-select';
import {get} from '../../lib/cookie';

@connect(state => ({
    receptionDay:          state.receptionDay,
    receptionStaff:        state.receptionStaff,
    receptionYmJsCode:     state.receptionYmJsCode,
    receptionPossibleDays: state.receptionPossibleDays,
    receptionSave:         state.receptionSave
}))
export default class Reception extends React.Component {

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    static propTypes = {
        receptionDay:          PropTypes.object,
        receptionStaff:        PropTypes.object,
        receptionYmJsCode:     PropTypes.object,
        receptionPossibleDays: PropTypes.object,
        receptionSave:         PropTypes.object,
    };

    state = {
        selectedDay: '',
        loading: false, // ожидаем ответ от сервер
        user:    { //данные пользователя
            name:     '',
            email:    '',
            phone:    '',
            validate: false //активировать ли нам кнопку "вперед"
        },
        exp: { //данные для получения рассписания в выбранный день
            doctorID: 'all', //любой врач (первый в списке)
            causeID: '1', //любая услуга (та что по умолчанию)
            year: undefined, //год
            week: undefined, //неделя
            month: undefined, //месяц
            day: undefined //день
        },
        step: 1, //первый этап формы
        reason: '', //прочие сведения от клиента
        time: undefined, //время
        date: undefined, //дата приема
        doctorId: undefined, //номер доктора
        seatId: undefined //что что что номер кресла
    };

    static needs = [
        ReceptionAction.getStaff,
        ReceptionAction.getYmJsEventCode,
        ReceptionAction.getPossibleDays
    ];

    componentDidMount = () => {
        this.props.dispatch(ReceptionAction.getStaff());
        this.props.dispatch(ReceptionAction.getYmJsEventCode());
        this.getPossibleDays();

        $('#Reception').fadeIn(200);
        $('body').on('click', '.reception__time > button', (e) => {
            $('.reception__time > button').removeClass('active');
            $(e.target).addClass('active');
        });
        $('body').on('click', '.react-datepicker__day', (e) => {
            $('.react-datepicker__day').removeClass('active');
            $('.reception__time > button').removeClass('active');
            $(e.target).addClass('active');
        });
    };

    /**
     * Получение массива доступных дат
     */
    getPossibleDays = () => {
        let sendData = {
            causeID: this.state.exp.causeID,
            doctorID: this.state.exp.doctorID
        };
        this.setState({
            time: undefined,
            exp: sendData
        });
        this.props.dispatch(ReceptionAction.getPossibleDays(sendData));
console.log('this.props.receptionPossibleDays.possibleDays', this.props.receptionPossibleDays.possibleDays);
console.log('this.state.exp.causeID', this.state.exp.causeID);
        let { receptionPossibleDays } = this.props;

        if (receptionPossibleDays.size === 0) receptionPossibleDays.possibleDays = [];
        if (receptionPossibleDays.possibleDays === undefined) receptionPossibleDays.possibleDays = [];
    };

    selectedDay = (data) => {
        if (!data) return;

        this.state.selectedDay = data;
        let date = moment(data);

        let sendData = {
                year: date.format('YYYY'),
                week: date.format('WW'),
                month: date.format('MM'),
                day: date.format('DD'),
                causeID: this.state.exp.causeID,
                doctorID: this.state.exp.doctorID
            };
        this.setState({
            date: date.format('DD.MM.YYYY'),
            time: undefined,
            exp: sendData
        });
        this.props.dispatch(ReceptionAction.getOneDay(sendData));
    };

    selectedParam = (data) =>{
        this.setState({
            time: data.time,
            doctorId: data.doctorId,
            seatId: data.seatId
        });
    };

    selectedCause = setParam =>{
        this.selectedExp(setParam, 'causeID');
        this.getPossibleDays();
        this.selectedDay(this.state.selectedDay);
    };

    selectedDoctor = setParam =>{
        this.selectedExp(setParam, 'doctorID');
        this.getPossibleDays();
        this.selectedDay(this.state.selectedDay);
    };

    selectedExp = (setParam, type) =>{
        let newExp = this.state.exp;
        newExp[type] = setParam.value;
        this.setState({
            exp: newExp
        });
        this.props.dispatch(ReceptionAction.getOneDay(newExp));
    };

    editOther = () => {
        this.setState({
            reason: this.refs.reason.value
        });
    };

    validation = (value, type) => {
        let re = '';
        switch (type){
            case 'email':
                re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                break;
            case 'phone':
                re = /[0-9]{5,}/;
                break;
            case 'name':
                re = /[A-zА-я]{3,}/;
                break;
        }
        return re.test(value);
    };

    editUserParams = () => {
        let newUserParam = this.state.user;
        newUserParam.name = this.refs.name.value;
        newUserParam.phone = this.refs.phone.value;
        newUserParam.email = this.refs.email.value;
        newUserParam.validate = this.validation(newUserParam.phone, 'phone') && this.validation(newUserParam.name, 'name');

        this.setState({
            user: newUserParam
        });
    };

    save = () => {
        let { receptionYmJsCode, params } = this.props;

        let param = params ? get('airstom_calltrack_info',  params) : {},
            reason = this.state.reason;

        if(param.referer){
            reason += `\nReferer:[${param.referer}]`;
        }
        if(param.uri){
            reason += `\nuri: [${param.uri}]`;
        }

        let sendParams = {
            time: this.state.time, //время приема
            date: this.state.date, //дата приема
            seat_id: this.state.seatId, // номер кресла
            reason: reason, //прочие сведения
            staff_id: this.state.doctorId, //номер доктора
            cause_id: this.state.exp.causeID, //номер услуги
            phone: this.state.user.phone, //телефон клиента
            fio: this.state.user.name, //ФИО клента
            email: this.state.user.email //почта клиента
        };
        this.props.dispatch(ReceptionAction.save($.param(sendParams)));
        this.setState({
            loading: true
        });

        /**
         * Цель «JavaScript-событие» yandex metrics
         */
        receptionYmJsCode = receptionYmJsCode.toJSON();
        let ymJsEventCode = receptionYmJsCode.ymJsEventCode.ym_js_event_code;
        if (!!ymJsEventCode) {
            eval(ymJsEventCode);
        }
    };

    setStep = (num) => {
        this.setState({
            step: num
        })
    };

    exit = (selector) => {
        this.context.router.push({query: {}, state: {}, pathname:'/'});
        ModalClose(selector);
    };

    render(){
        let {receptionDay, receptionStaff, receptionSave} = this.props,
            _this = this,
            timeBoxes = [],
            doctors = undefined,
            active = false;

        receptionDay = receptionDay.toJSON();
        receptionStaff = receptionStaff.toJSON();
        receptionSave = receptionSave.toJSON();

        if(receptionSave.success === true) {
            this.props.dispatch(ReceptionAction.clear());
            this.setState({
                step: 3,
                loading: true
            });
            setTimeout(()=>{
                _this.exit();
            }, 3000);
        }else if (this.state.step === 2 && receptionSave.errors !== undefined){
            this.props.dispatch(ReceptionAction.clear());
            this.setState({
                step: 4,
                loading: true
            });
        }

        //добавим пункт для врача
        if( receptionStaff['doctorList'] !== undefined){
            receptionStaff['doctorList'].push({
                label: 'Затрудняюсь ответить',
                value: 'all'
            });
        }

        Object.keys(receptionDay).forEach(function(item) {
            doctors = receptionDay[item].data;
            doctors.forEach(function (doctor) {
                if(doctor.active === true && receptionDay[item].doctorId === undefined){
                    receptionDay[item].active = true;
                    receptionDay[item].seatId = doctor.seatId;
                    receptionDay[item].doctorId = doctor.doctorId;
                }
            });

            timeBoxes.push(
                <button
                    onClick={() => {_this.selectedParam({
                        time:       receptionDay[item].time,
                        doctorId:   receptionDay[item].doctorId,
                        seatId:     receptionDay[item].seatId
                    })}}
                    disabled={!receptionDay[item].active}
                    key={item}>
                        { receptionDay[item].time }
                </button>
            );
            doctors = undefined;
            active = receptionDay[item].active = false;
        });
        if(receptionDay.length === 0){
            timeBoxes.push(<span style={{color: '#333'}}>Нет свободного времени на выбранную дату</span>);
        }



        let sendActive = (this.state.time !== undefined &&
                            this.state.date !== undefined &&
                            this.state.seatId !== undefined);

        return(
            <div className="modal__wrap" id="Reception">
                <div className="modal modal--open">
                    <button type="button" className="modal__close" onClick={()=>{_this.exit('#Reception')}}>
                        <span>×</span>
                    </button>
                    <div>
                        <p className="modal__title">Запишитесь на приём</p>
                        <div className="reception_steps">
                            <div className={'reception_step ' + (this.state.step === 1 ? 'active' : '')}>
                                <div className="form__block reception__slide-block">
                                    <label className="form__block-label" htmlFor="receptionFIO">Ваше ФИО*</label>
                                    <input className="form__block-input" placeholder="Иван Иванович" onChange={_this.editUserParams} id="receptionFIO" ref='name' value={_this.state.user.name}/>
                                </div>
                                <div className="form__block reception__slide-block">
                                    <label className="form__block-label" htmlFor="receptionPhone">Телефон для связи*</label>
                                    <input className="form__block-input" placeholder="8123456789 или 123456" onChange={_this.editUserParams} id="receptionPhone" ref='phone' value={_this.state.user.phone}/>
                                </div>
                                <div className="form__block reception__slide-block">
                                    <label className="form__block-label" htmlFor="receptionEmail">Email</label>
                                    <input className="form__block-input" placeholder="mail@post.com" onChange={_this.editUserParams}  id="receptionEmail" ref='email' value={_this.state.user.email}/>
                                </div>
                                <div className="reception__bottom">
                                    <button type="button"
                                            onClick={()=>{_this.setStep(2)}}
                                            className="btn reception__slide-btn"
                                            disabled={!this.state.user.validate}>
                                        Далее
                                    </button>
                                </div>
                            </div>
                            <div className={'reception_step ' + (this.state.step === 2 ? 'active' : '')}>
                                <div>
                                    <div className="reception__slide-block">
                                        <div className="form__block reception__slide-block">
                                            <label className="form__block-label">Выбор врача</label>
                                            <Select
                                                clearable={false}
                                                noResultsText="Не найдено"
                                                searchable={false}
                                                name="doctorID"
                                                onChange={_this.selectedDoctor}
                                                value={_this.state.exp.doctorID}
                                                options={receptionStaff['doctorList']}
                                            />
                                        </div>
                                        <div className="form__block reception__slide-block">
                                            <label className="form__block-label">Укажите причину обращения</label>
                                            <Select
                                                clearable={false}
                                                noResultsText="Не найдено"
                                                searchable={false}
                                                name="causeID"
                                                onChange={_this.selectedCause}
                                                value={_this.state.exp.causeID}
                                                options={receptionStaff['causeList']}
                                            />
                                        </div>
                                    </div>
                                    <div className="reception__slide-block">
                                        <div className="form__block reception__slide-block">
                                            <label className="form__block-label">Дополнительная информация</label>
                                            <textarea className="form__block-textarea"
                                                      ref="reason"
                                                      value={this.state.reason}
                                                      onChange={_this.editOther}/>
                                        </div>
                                    </div>
                                    <div className="reception__date">
                                        <div className="reception__day">
                                            <DatePicker
                                                includeDates={this.props.receptionPossibleDays.possibleDays}
                                                ref="calendar"
                                                inline
                                                locale='ru'
                                                onChange={event=>{this.selectedDay(event)}}
                                            />
                                        </div>
                                        <div className="reception__time">
                                            {timeBoxes}
                                        </div>
                                    </div>
                                </div>
                                <div className="reception__bottom">
                                    <button type="button"
                                            disabled={this.state.loading}
                                            onClick={()=>{_this.setStep(1)}}
                                            className="btn btn-close reception__slide-btn"
                                    >
                                        Назад
                                    </button>
                                    <button type="button"
                                            disabled={!sendActive || this.state.loading}
                                            onClick={_this.save}
                                            className="btn reception__slide-btn"
                                    >
                                        {this.state.loading ? 'Запись...' : 'Записаться'}
                                    </button>
                                </div>
                            </div>
                            <div className={'reception_step ' + (this.state.step === 3 ? 'active' : '')}>
                                Вы успешно записались на приём!
                            </div>
                            <div className={'reception_step ' + (this.state.step === 4 ? 'active' : '')}>
                                Ошибка при регистрации на прием.
                                <button type="button"
                                        onClick={()=>{_this.setStep(2);this.setState({loading: false})}}
                                        className="btn btn-close reception__slide-btn">
                                    Вернуться
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}