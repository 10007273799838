import Immutable from 'immutable';

const defaultState = new Immutable.List();

export default function receptionYmJsCodeReducer (state = defaultState, action) {
    switch (action.type) {
        case 'GET_YM_JS_CODE':
            return new Immutable.fromJS(action.res.data);
            break;
        default:
            return state;
    }
}
