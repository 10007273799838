import React from 'react';
import PropTypes from 'prop-types';
import { connect }            from 'react-redux';

export default class AddReviews extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    componentWillReceiveProps = (nextProps) => {
        Modal('#modalAddReview');
    };

    close = (selector) => {
        this.context.router.push({query: {}, state: {}, pathname:'/'});
        ModalClose(selector);
    };

    blockChange = (obj) => {
        if($(obj.target).val()!=''){
            $(obj.target).removeClass('form__block--error');
        }
    };

    confirmForm = () => {
        let _this = this;
        let fields = {'name':1, 'email':1, 'text':1};
        $.each(fields, function(i, n) {
            $('#modalAddReview input[name='+i+'], #modalAddReview textarea[name='+i+']').removeClass('form__block--error');
        });

        $.ajax({
            type: 'POST',
            url: `${window.__API_URL__}/front/v1/reviews/${window.__HOSTNAME__}/add/`,
            data: {
                name:  $('#modalAddReview #reviewNAME').val(),
                email: $('#modalAddReview #reviewEMAIL').val(),
                text:  $('#modalAddReview #reviewTEXT').val()
            }
        }).done(function(data) {
            if(data.type == 'success') {
                $('.modal__title').hide();
                $('.modal__form').hide();
                $('.modal__footer').hide();
                $('.modal__success').show();
                setTimeout(function(){
                    ModalClose('#modalAddReview');
                    $('.modal__title').show();
                    $('.modal__form').show();
                    $('.modal__footer').show();
                    $('.modal__success').hide();
                    _this.context.router.push({query: {}, state: {}, pathname:'/'});
                }, 2500);
            } else {
                $.each(data.data, function(i, n) {
                    $('#modalAddReview input[name='+i+'], #modalAddReview textarea[name='+i+']').addClass('form__block--error');
                });
            }
        });
    };

    render() {
        let divStyle = { display: 'block' };

        return (
            <div className="modal__wrap" style={divStyle}>
                <div id="modalAddReview" className="modal modal--small modal--open">
                    <button type="button" className="modal__close" onClick={this.close.bind(this, '#modalAddReview')}><span>&times;</span></button>
                    <p className="modal__title">Написать отзыв</p>
                    <div className="modal__form">
                        <div className="modal__form--full">
                            <div className="form__block">
                                <label className="form__block-label" htmlFor="reviewNAME">Ваше имя</label>
                                <input className="form__block-input" id="reviewNAME" name="name" type="text" onChange={this.blockChange} />
                            </div>
                            <div className="form__block">
                                <label className="form__block-label" htmlFor="reviewEMAIL">Ваш e-mail</label>
                                <input className="form__block-input" id="reviewEMAIL" name="email" type="email" onChange={this.blockChange} />
                            </div>
                            <div className="form__block">
                                <label className="form__block-label" htmlFor="reviewTEXT">Ваш отзыв</label>
                                <textarea rows="5" className="form__block-textarea" id="reviewTEXT" name="text" onChange={this.blockChange}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal__footer">
                        <button type="submit" className="btn btn--center btn--long"  onClick={this.confirmForm}>Отправить отзыв</button>
                    </div>
                    <div className="modal__success">
                        <p className="modal__success-title">Отзыв успешно отправлен!</p>
                        <p className="modal__success-text">Спасибо за оставленный отзыв! Сразу после проверки он будет опубликован.</p>
                    </div>
                </div>
            </div>
        );
    }
}
