let step = 1,
    end = 0;

export const stopVoice = data => {
    data.speech.cancel();
    end = 0;
    return {
        type: 'SET_VOICE',
        data: data
    }
};

export const playVoice = data => {
    let msg = new SpeechSynthesisUtterance();
    let voices = speechSynthesis.getVoices();
    function begin(item){
        console.log("item: ", item);
        msg.volume = data.volume;
        msg.rate = 1;
        msg.pitch = 1.2;
        msg.text = item;
        msg.lang = "ru-RU";
        msg.name = "Russian Female";
        msg.voiceURI = "Russian Female";
        msg.onstart = () => {
            if(step > end){
                speechSynthesis.cancel();
            }
        };
        msg.onend = () => {
            if(step < end){
                if(data.text[step] !== undefined){
                    begin(data.text[step]);
                    step++;
                }
            }
            if(step === end){
                console.log("END: ", data.id);
                data.object.setState({
                    play: false
                });
            }
        };
        speechSynthesis.speak(msg);
    }


    if(data.text !== false){
        if(data.speech !== undefined){
            data.speech.cancel();
        }

        step = 1;
        end = data.text !== false ? data.text.length : 0;
            begin(data.text[0]);

        return {
            type: 'SET_VOICE',
            data: {
                speech: speechSynthesis,
                id: data.id
            }
        };
    }else{
        if(data.speech !== undefined){
            data.speech.cancel();
        }
        return {
            type: 'SET_VOICE',
            data: {
                speech: speechSynthesis,
                id: data.id
            }
        }
    }
};