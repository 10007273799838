//import cookie from 'js-cookie';

// возвращает cookie с именем name, если есть, если нет, то undefined
function getCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires === "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}



var defaultStatus = {
    active: false
};

export function getParam() {
    return {
        type: 'GET_EYE',
        data: getCookie('eye_status') === undefined ? defaultStatus : JSON.parse(getCookie('eye_status'))
    }
}

export function setParam(setting) {
    setCookie('eye_status', JSON.stringify(setting), {path: '/'});
    //cookie.set('eye_status', setting,  { expires: 7 ,  path: '/'});
    return {
        type: 'SET_EYE',
        data: JSON.parse(getCookie('eye_status'))
    }
}