import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { Link }               from 'react-router';
import Immutable              from 'immutable';

@connect(state => ({ staff: state.staff }))

export default class Staff extends React.Component {
    static contextTypes = {
        router: PropTypes.object.isRequired
    };
    static propTypes = {
        staff:    PropTypes.any.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    componentWillReceiveProps = (nextProps) => {
        Modal('#modalStaff');
    };

    close = (selector) => {
        this.context.router.push({query: {}, state: {}, pathname:'/'});
        ModalClose(selector);
    };


    render() {
        const { staff } = this.props;
        let divStyle = { display: 'block' };

        return (
            <div className="modal__wrap" style={divStyle}>
                <div id="modalStaff" className="modal modal--open">
                    <button type="button" className="modal__close" onClick={this.close.bind(this, '#modalStaff')}><span>&times;</span></button>
                    <p className="modal__title">Сотрудники</p>
                    <div className="modal__form">
                        <div className="staff">
                            {staff.map(function (item, index) {
                                return(
                                    <div key={index} className="staff__item">
                                        <a href="#" className="staff__item-name">{item.get('name')}</a>
                                        <div className="staff__item-post">{item.get('whois')}</div>
                                        <div className="staff__item-info">{item.get('quote')}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="modal__footer">
                        <Link to="/reception/" className="btn btn--center btn--long">Записаться на приём</Link>
                    </div>
                </div>
            </div>
        );
    }
}
