import React from 'react';
import PropTypes from 'prop-types';
import PhoneList              from '../other/PhoneList';
import { Link }               from 'react-router';

export default class Contact extends React.Component {
    static propTypes = {
        site:   PropTypes.any.isRequired,
        params: PropTypes.object.isRequired
    };


    initMap = () => {
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: {lat: -34.397, lng: 150.644}
        });
        var geocoder = new google.maps.Geocoder();

        document.getElementById('submit').addEventListener('click', function() {
            geocodeAddress(geocoder, map);
        });
    };

    geocodeAddress = (geocoder, resultsMap) => {
        var address = document.getElementById('address').value;
        geocoder.geocode({'address': address}, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                resultsMap.setCenter(results[0].geometry.location);
                var marker = new google.maps.Marker({
                    map: resultsMap,
                    position: results[0].geometry.location
                });
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    };

    componentDidMount = () => {
        $('.contact .contact__map').append(`<div id="map" style="width: 100%;height: 100%;"></div>`);
        $('.contact').append(`
            <script>
                function initMap() {
                    var map = new google.maps.Map(document.getElementById('map'), {
                        zoom: 15,
                        scrollwheel: false,
                        center: {lat: -34.397, lng: 150.644}
                    });
                    var geocoder = new google.maps.Geocoder();

                    geocodeAddress(geocoder, map);
                }

                function geocodeAddress(geocoder, resultsMap) {
                    var address = '${this.props.site.get('address')}';
                    geocoder.geocode({'address': address}, function(results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            resultsMap.setCenter(results[0].geometry.location);
                            var marker = new google.maps.Marker({
                                map: resultsMap,
                                position: results[0].geometry.location
                            });
                        } else {
                            alert('Geocode was not successful for the following reason: ' + status);
                        }
                    });
                }
            </script>
            <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCGkeF2EjRjEyuPnqZk1-wIHF9v6L1RNKI&signed_in=true&callback=initMap" async defer></script>`
        );
    };

    render() {
        const { site } = this.props;

        return (
            <section className="contact">
                <div className="contact__map">
                </div>
                <div className="container">
                    <div className="contact__info">
                        <h4 className="h4 contact--title">Контакты</h4>
                        <p className="contact__info-item"><strong>Адрес</strong><br />{site.get('address')}</p>
                        <div className="contact__info-item"><strong>Телефон</strong><br />
                            <PhoneList params={this.props.params} phones={site.get('phone')}/>
                        </div>
                        <p className="contact__info-item"><strong>E-mail</strong><br /><a href={'mailto:'+site.get('email')}>{site.get('email')}</a></p>
                        <p className="contact__info-item"><strong>Режим работы</strong><br /><span dangerouslySetInnerHTML={{__html: site.get('time_work')}}></span></p>
                        <Link to="/reception/" className="btn contact__btn--contact">Записаться на приём</Link>
                    </div>
                </div>
            </section>
        );
    }
}
