import Immutable from 'immutable';

const defaultState = new Immutable.List();

export default function priceReducer(state = defaultState, action) {
    switch (action.type) {
        case 'GET_PRICES':
            return new Immutable.fromJS(action.res.data.data);
        default:
            return state;
    }
}
