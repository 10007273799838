import Immutable from 'immutable';

const defaultState = new Immutable.List();

export default function receptionSaveReducer (state = defaultState, action) {
    switch (action.type) {
        case 'GET_SAVE':
            return new Immutable.fromJS(action.res.data);
            break;
        default:
            return Immutable.fromJS({
                status: false
            });
    }
}
