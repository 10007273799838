import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators }  from 'redux';
import Immutable               from 'immutable';
import { connect }             from 'react-redux';

import * as SiteActions       from 'actions/SiteActions';
import * as OffersActions     from 'actions/OffersActions';
import * as PriceActions      from 'actions/PriceActions';
import * as NewsActions       from 'actions/NewsActions';
import * as ReviewsActions    from 'actions/ReviewsActions';

import Header            from 'components/header/Header';
import Phrase            from 'components/phrase/Phrase';
import Slider            from 'components/slider/Slider';
import Price             from 'components/price/Price';
import About             from 'components/about/About';
import Speak             from 'components/speak/Speak';
import Contact           from 'components/contact/Contact';
import Footer            from 'components/footer/Footer';

@connect(state => ({
    meta: state.meta,
    news: state.news,
    site: state.site,
    reviews:state.reviews,
    price:  state.price,
    offers: state.offers
}))

export default class Main extends React.Component {
    static propTypes = {
        children: PropTypes.object,
        dispatch: PropTypes.func.isRequired
    };

    /*static needs = [
        SiteActions.getSiteinfo,
        PriceActions.getPrices,
        NewsActions.getNews,
        ReviewsActions.getReviews,
        OffersActions.getOffers
    ];*/

    render() {
        const { site, news, price, offers, reviews, dispatch } = this.props;

        return (
            <div>
                <Header site={site} {...bindActionCreators(SiteActions, dispatch)} />

                <Phrase site={site} {...bindActionCreators(SiteActions, dispatch)} />

                <Slider offers={offers} {...bindActionCreators(OffersActions, dispatch)} />

                <Price price={price} site={site} {...bindActionCreators(SiteActions, dispatch)} />

                <About site={site} news={news} {...bindActionCreators(SiteActions, dispatch)} />

                <Speak site={site} reviews={reviews} {...bindActionCreators(ReviewsActions, dispatch)}  />

                <Contact site={site} {...bindActionCreators(SiteActions, dispatch)} />

                <Footer site={site} {...bindActionCreators(SiteActions, dispatch)} />
            </div>
        );
    }
}
