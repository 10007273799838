import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { Link }               from 'react-router';
import Immutable              from 'immutable';
import Voice                    from 'lib/voice';

@connect(state => ({
    site: state.site,
    reviews: state.reviews,
    eye: state.eye
}))

export default class Speak extends React.Component {
    static propTypes = {
        reviews:  PropTypes.any.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    componentDidMount = () => {
        $('.speak__block-wrap').slick({
            'prevArrow': '<svg class="speak__block-arrow--left" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22.1 41.5" style="enable-background:new 0 0 22.1 41.5;" xml:space="preserve"><polyline points="21.5,40.9 1.5,20.9 21.5,0.9 " /></svg>',
            'nextArrow': '<svg class="speak__block-arrow--right" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 22 41.5" style="enable-background:new 0 0 22 41.5;" xml:space="preserve"> <polyline points="0.7,0.8 20.7,20.8 0.7,40.8 " /> </svg>'
        });
    };

    render() {
        const { reviews, site } = this.props;

        return (
            <section className="container speak">
                <h3 className="h3 speak--title">Что о нас говорят</h3>
                <div className="speak__text" dangerouslySetInnerHTML={{__html: site.get('about_review')}}></div>
                <div className="speak__btn">
                    <Link to="/reviews/add/" className="btn speak__btn--speak">Оставить отзыв</Link>
                </div>
                <div className="clearfix"></div>

                <div className="speak__block">
                    <div className="speak__block-wrap">
                        {reviews.map(function(item, index){
                            return (
                                <div key={index} className="speak__block-item">
                                    <div className="speak__block-item-review">{item.get('text')}</div>
                                    <div className="speak__block-item-author">{item.get('name')}</div>
                                    {/*{this.props.eye.active ? <Voice text={item.get('text')}/> : null}*/}
                                </div>
                            );
                        })}
                    </div>
                    {reviews.size>1 ? <Link className="speak__block-link" to="/reviews/">Посмотреть все отзывы</Link> : `` }
                </div>

            </section>
        );
    }
}
